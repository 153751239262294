// button overrides
.btn-primary {
  &:hover {
    background-color: $secondary !important;
    border-color: $secondary !important;
  }
}
.btn-secondary {
  color: $white !important;
}

.btn-tertiary, .btn-light {
  background-color: $white !important;
  border-color: $white !important;
  color: $primary !important;
  &:hover {
    color: $white !important;
  }
}

.btn-ghost {
  color: $primary !important;
  &:hover {
    background-color: $primary;
    color: $white !important;
  }
}

// bg overrides
a.bg-primary {
  &:hover {
    background-color: $secondary !important;
  }
}

a.text-white:hover,
a.text-white:focus {
  color: $white !important;
}

@media only screen and (min-width: 1400px) {
  .oversized-image {
    left: 65%!important;
  }
  .dropdown-body{
    width: max-content;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1399px) {
  .oversized-image {
    left: 50%;
  }
}

.bg-white-25::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white !important;
  opacity: 0.5 !important;
}
.bg-white-25::-moz-placeholder {
  /* Firefox 19+ */
  color: white !important;
  opacity: 0.5 !important;
}
.bg-white-25:-ms-input-placeholder {
  /* IE 10+ */
  color: white !important;
  opacity: 0.5 !important;
}
.bg-white-25:-moz-placeholder {
  /* Firefox 18- */
  color: white !important;
  opacity: 0.5 !important;
}

.navbar-form {
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $primary !important;
    opacity: 0.5;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: $primary !important;
    opacity: 0.5;
  }
  input:-ms-input-placeholder {
    /* IE 10+ */
    color: $primary !important;
    opacity: 0.5;
  }
  input:-moz-placeholder {
    /* Firefox 18- */
    color: $primary !important;
    opacity: 0.5;
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
.font-secondary{
  font-family: 'MuseoSans', $font-family-sans-serif !important;
}

.font-primary{
  font-family: $font-family-sans-serif !important;
}

.scale-3\/2 {
  transform: scale(1.5);
}

.scale-md-5\/4 {
  @media only screen and (min-width: 720px) {
    transform: scale(1.25);
  }
}
.scale-lg-3\/2 {
  @media only screen and (min-width: 960px) {
    transform: scale(1.5);
  }
}

// mega menu
.mega-link {
  .dropdown-pointer {
    display: none;
  }
  &.is-expanded {
    .dropdown-pointer {
      display: block;
      width: 1rem;
      height: 1rem;
      background: $white;
      left: 50%;
      top: 3.25rem;
      transform: translateX(-50%) rotate(45deg);
    }
  }
}
.mega-wrapper {
  width: calc(100vw - 2rem);
  left: 1rem;
  top: calc(100% + 0.5rem);
  display: none;
  &.is-expanded {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .mega-grandchildren {
    padding-left: calc(42px + 1rem);
    a {
      color: $dark;
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
    &--last {
      a {
        color: $primary;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .mega-infohub {
    padding-left: calc(42px + 1rem);
  }
}
.mega-grandchildren--mobile {
  padding-left: calc(36px + 0.5rem);
  a {
    color: $dark;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

// basic banner
.basic-banner {
  img {
    width: 150%;
  }
  @media only screen and (min-width: 480px) {
    img {
      width: 100%;
    }
  }
  @media only screen and (min-width: 992px) {
    img {
      width: 150%;
    }
  }
  @media only screen and (min-width: 1140px) {
    min-height: 400px;
    img {
      width: 100%;
      max-width: 600px;
    }
  }
  @media only screen and (min-width: 1600px) {
    img {
      right: 3rem;
      width: calc(100% - 3rem);
    }
  }
}

// footer
footer {
  input[type="text"] {
    &::placeholder {
      color: $light;
    }
  }
}

// forms
.bg-primary {
  .form-id-18 {
    input[type="text"], input[type="email"] {
      color: #ffffff;
      // text-white fullwidth border-white-50 bg-white-25
      background: transparentize(#ffffff, 0.75);
      border: 1px solid transparentize(#ffffff, 0.5);
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: transparentize(#ffffff, 0.5);
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: transparentize(#ffffff, 0.5);
      }
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: transparentize(#ffffff, 0.5);
      }
    }
  }
}

.disabled { pointer-events: none; }
// Negative Margin
// Just prefix "n". ml-10 becomes nml-10
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m) {
      @each $size, $length in $spacers {
        .#{$abbrev}t-firstchild#{$infix}-#{$size} > *:first-child {
          #{$prop}-top: $length !important;
        }

        .#{$abbrev}b-lastchild#{$infix}-#{$size} > *:last-child {
          #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}b-child#{$infix}-#{$size} > * {
          #{$prop}-bottom: $length !important;
        }
      }
    }

    @each $prop, $abbrev in (top: t, right: r, bottom: b, left: l) {
      @each $size, $length in $spacers {
        .#{$prop}#{$infix}-#{$size} { #{$prop}: $length !important; }
        .#{$prop}#{$infix}-n#{$size} { #{$prop}: -$length !important; }
      }
    }

    @each $prop, $abbrev in (fixed: fixed, absolute: absolute, static: static, sticky: sticky) {
      .position#{$infix}-#{$prop} { position: #{$prop} !important; }
    }
    @each $prop, $abbrev in (left: left, right: right, center: center) {
      .text#{$infix}-#{$prop} { text-align: #{$prop} !important; }
    }

    @each $prop, $size in (1: $display1-size, 2: $display2-size, 3: $display3-size, 4: $display4-size) {
      .display#{$infix}-#{$prop} { font-size: #{$size} !important; }
    }

    @each $prop, $size in ( 20: 20%, 25: 25%, 50: 50%, 75: 75%, 100: 100%, auto: auto) {
      .w#{$infix}-#{$prop} { width: #{$size} !important; }
      .mw#{$infix}-#{$prop} { max-width: #{$size} !important; }
    }

    @each $size, $length in $alpha {
      .alpha#{$infix}-#{$size} { opacity: $length !important; }
    }
    // colors
    @each $prop, $value in $theme-colors {
      .text#{$infix}-#{$prop} { color: $value !important; }
      .text#{$infix}-#{$prop}-hover:hover { color: $value !important; }
      .bg#{$infix}-#{$prop} { background-color: $value !important; }

      .divide#{$infix}-#{$prop} > :not([hidden]) ~ :not([hidden]) {
        --tw-divide-opacity: 1;
        border-color: rgba($value,var(--tw-divide-opacity));
      }

      @each $size, $length in (10: 0.10, 15: 0.15, 25: 0.25, 33: 1/3, 50: 0.50, 66: 2/3, 75: 0.75, 85: 0.85, 90: 0.9) {
        .bg#{$infix}-#{$prop}-#{$size} { background-color: rgba($value, $length) !important; }
        .border#{$infix}-#{$prop}-#{$size} { border-color: rgba($value, $length) !important; }
        .text#{$infix}-#{$prop}-#{$size} { color: rgba($value, $length) !important; }
        
        .divide#{$infix}-#{$prop}-#{$size} > :not([hidden]) ~ :not([hidden]) {
          --tw-divide-opacity: #{$length};
          border-color: rgba($value, var(--tw-divide-opacity));
        }
      }

      .btn#{$infix}-#{$prop} {
        @include button-variant($value, $value);
      }
    }

    @each $size in (0, 2, 3, 4) {
      .border#{$infix}-#{$size} { border-width: #{$size}px !important; }
    }
    @each $prop, $abbrev in (top: t, right: r, bottom: b, left: l) {
      @each $size in (0, 1) {
        .border-#{$prop}#{$infix}-#{$size} { border-#{$prop}: #{$size}px solid $border-color !important; }
      }
    }


    .divide#{$infix}-x > :not([hidden]) ~ :not([hidden]) {
      --tw-divide-x-reverse: 0;
      border-right-width: calc(1px * var(--tw-divide-x-reverse));
      border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
    }
    .divide#{$infix}-y > :not([hidden]) ~ :not([hidden]) {
      --tw-divide-y-reverse: 0;
      border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
      border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    }

  }


}




.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.top-header {
  top: 73px !important;
}

.badge-secondary {
  color: $white;
  &:hover {
    color: $white !important;
  }
}
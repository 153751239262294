/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'),
       url('../fonts/roboto-v20-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/roboto-v20-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/roboto-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/roboto-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('MuseoSans'),
       url('../fonts/museosans-700.otf') format('opentype'), /* Chrome 26+, Opera 23+, Firefox 39+ */
}

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('MuseoSans'),
    url('../fonts/museosans-500.otf') format('opentype'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
}


@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('MuseoSans'),
    url('../fonts/museosans-300.otf') format('opentype'),
}

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('MuseoSans'),
    url('../fonts/museosans-100.otf') format('opentype'),
}
